import React, { useEffect } from "react";

import { gql, useMutation } from "@apollo/client";

import { removeAccessToken, removeRefreshToken } from "@/account/user";
import { Icon, IconTypeEnum } from "@/components/common/icon";
import { isBrowser } from "@/utils/env";

import "./styles.scss";

const BLOCK_USER_MUTATION = gql`
  mutation blockUser($token: String!) {
    blockUser(token: $token)
  }
`;

const Deactivate = () => {
  const [blockUser,
    { data: blockUserData, loading: blockUserLoading, error: blockUserError },
  ] = useMutation(BLOCK_USER_MUTATION);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    blockUser({ variables: { token: urlParams.get("token") } });
  }, [blockUser]);

  useEffect(() => {
    if (blockUserData && !blockUserLoading && !blockUserError) {
      removeAccessToken();
      removeRefreshToken();
    } else if (blockUserError) {
      // todo: check error code, handle expired token etc.
      console.log("loginError", blockUserError);
    }
  }, [blockUserData, blockUserLoading, blockUserError]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <div className="block-user__container block-user__container--center">
      <div className="block-user__inner">
        <div className="update-password-success__circle block-user__icon">
          <Icon type={IconTypeEnum.Tick} />
        </div>
        <h2 className="block-user__description">Аккаунт успешно удалён</h2>
      </div>
    </div>
  );
};

export default Deactivate;
